.web-page-widget .web-page-widget-btn {
    position:absolute!important;
    width:1px!important;
    max-height:0!important;
    padding:0!important;
    overflow:hidden!important;
    white-space:nowrap!important;
    background-color: #c8daea;
    transition: 10s;
}

.web-page-widget:hover {
    border: 1px solid #c8daea;
}

.web-page-widget:hover .web-page-widget-btn {
    position:inherit!important;
    width:inherit!important;
    max-height:inherit!important;
    padding:inherit!important;
}

.web-page-widget:hover {
    min-height: 54px;
}

.mobile-view-css {
    padding: 16px 9px 28px;
    border-radius: 8px;
    background-color: rgb(44,46,54);
}

.mobile-view-css-child {
    width: 42vh;
    height: 70vh;
    overflow-y: auto;
}

.laptop-view-css {
    padding: 9px;
    border-radius: 8px;
    background-color: rgb(44,46,54);
}