.left_panel_active {
    background: #000000;
    font-weight: bold;
    color: #fff !important;
}

.left_panel_item {
    width: 240px;
    transition: 0.1s;
    color: #c8c8c8;
}

.left_panel_item:hover {
    background: #ff5c35;
    color: #fff;
}

.its-my-web-left-panel {
    position: relative;
    width: 260px;
}

@media only screen and (max-width : 768px) {
    .its-my-web-left-panel {
        position: fixed;
        z-index: 9999;
        height: 100%;
        width: 100%;
        top: 0;
    }
}