.gold_gradient {
    background: #B38728;

    background: linear-gradient(to bottom,
    rgba(191, 149, 63, 1) 0%,
    rgba(252, 246, 186, 1) 10%,
    rgba(179, 135, 40, 0.5) 25%,
    rgba(179, 135, 40, 0.8) 50%,
    rgba(179, 135, 40, 0.8) 55%,
    rgba(179, 135, 40, 0.6) 70%,
    rgba(191, 149, 63, 0.6) 75%,
    rgba(170, 119, 28, 0.5) 80%,
    rgba(170, 119, 28, 1) 100%);
}