.App {
    min-height: 100vh;
}

.cursor-pointer {
    cursor: pointer;
}

.hover-shadow, .hover-blue, .bg-hover-white {
    cursor: pointer;
}

.hover-shadow:hover{
    box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important;
}

.bg-hover-white:hover {
    background-color: #fff;
}

.bg-light-info {
    background-color: rgba(47, 117, 215, 0.4);
}

.bg-light-success {
    background-color: #E5F6DF;
}

.bg-light-danger {
    background-color: #f1d9d9;
}

.bg-light-secondary-1 {
    background-color: #e6e6e6;
}

.hover-blue:hover{
    background: rgba(47, 117, 215, 0.6);
    box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important;
    color: black;
}

.itsmyweb-title {
    border-left: 4px solid rgba(47, 117, 215, 1);
    background-color: rgba(47, 117, 215, 0.2);
}

.login-block, .register-block, .big-status-block {
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(https://images7.alphacoders.com/328/328672.jpg) no-repeat center center / cover;
    cursor: pointer;
    height: 100px;
    width: 100%;
}

.register-block{
    height: 200px;
}

.big-status-block{
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(https://images3.alphacoders.com/106/1062607.jpg) no-repeat center center / cover;
    height: 50vh;
}

.no-text-decor{
    text-decoration: none;
    color: inherit;
    cursor: pointer !important;
}

.no-text-decor:hover{
    text-decoration: none;
    color: inherit;
}

.img-object-fit-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.img-object-fit-contain {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.rounded-left-0 {
    border-radius:.25rem!important;
    border-top-left-radius:0 !important;
    border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
    border-top-right-radius:0 !important;
    border-bottom-right-radius: 0 !important;
}

.rounded-top-0 {
    border-top-right-radius:0 !important;
    border-top-left-radius: 0 !important;
}

.input-top {
    position: absolute;
    top: -12px;
    left: 12px;
    background: white;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #6c757d;
}

.form-control:-webkit-autofill {
    -webkit-background-clip: text;
}

.fs-12 {
    font-size: 12px;
}

.transition-medium {
    transition: 0.34s;
}

.hide-on-small-screen {
    display: block;
}

.show-on-small-screen {
    display: none;
}

@media only screen and (max-width : 991px) {
    .hide-on-small-screen {
        display: none !important;
        visibility:hidden;
    }

    .show-on-small-screen {
        display: block;
    }
}

.validate-input:invalid {
    border-color: #dc3545 !important;
}

.rounded-pm-1 {
    border-radius: 28px !important;
}

.fs-12 {
    font-size: 12px;
}

.fs-1_6vw {
    font-size: 1.6vw;
}

.fs-2_5vw {
    font-size: 2.5vw;
}

.fs_heading {
    transition: 400ms;
    font-size: calc(1.375rem + (10) * ((100vw - 320px) / (1920 - 320)));
}

.fs_heading2 {
    transition: 400ms;
    font-size: calc(1.2rem + (10) * ((100vw - 320px) / (1920 - 320)));
}

.fs_75c_10 {
    transition: 400ms;
    font-size: calc(0.75rem + (10) * ((100vw - 320px) / (1920 - 320)));
}

.fs_75c_4 {
    transition: 400ms;
    font-size: calc(0.75rem + (4) * ((100vw - 320px) / (1920 - 320)));
}

.fs_45c_4 {
    transition: 400ms;
    font-size: calc(0.45rem + (4) * ((100vw - 320px) / (1920 - 320)));
}